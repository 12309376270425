import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useGalleryList() {
  const toast = useToast()
  const statusOptions = [
    { label: 'Activate', value: 'Activate' },
    { label: 'Inactivate', value: 'Inactivate' },
  ]

  const fetchGallery = async (id) => {
    try {
      const response = await store.dispatch('app-gallery/fetchGallery', id);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetch setting',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const updateGallery = async (gallery) => {
    try {
      const response = await store.dispatch('app-gallery/updateGallery', gallery);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error update setting',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const deleteGallery = async (id) => {
    try {
      const response = await store.dispatch('app-gallery/deleteGallery', id);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error update setting',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const uploadImage = async (images) => {
    try {
      const response = await store.dispatch('app-gallery/uploadImage', images);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error upload image',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    toast,
    statusOptions,
    fetchGallery,
    updateGallery,
    deleteGallery,
    uploadImage,
  }
}
